import $ from 'jquery';

function scrollTopHandler(callback = ()=> {}) {
  var scrollTop = $('.scroll-top');
  if (scrollTop) {
    window.onscroll = function() {
      scrollFunction();
      callback();
    };

    scrollTop.on('click', function() {
      $('html, body').animate({
        scrollTop: 0
      }, '300');
    })
  }
}

function scrollFunction() {
  var scrollTopBtn = $('.scroll-top');
  if (scrollTopBtn) {
    if ($(window).scrollTop() > window.innerHeight * 2 || $(window).scrollTop() > window.innerHeight * 2) {
      scrollTopBtn.css('display', 'flex');
    } else {
      scrollTopBtn.css('display', 'none');
    }
  }
}

export { scrollTopHandler };
