function showMoreSeeLess() {
  $('.read-more-btn').on('click', function() {
    var ele = $(this);
    var speed = 1000;

    if (ele.find('i').hasClass('fa-arrow-down')) {
      ele.parents().find('.hidden-content').hide();
      ele.find('i').removeClass('fa-arrow-down');
      ele.find('i').addClass('fa-arrow-up');
      ele.parents().find('.hidden-content').removeClass('d-none');
      ele.parents().find('.hidden-content').show(speed);
    } else {
      ele.find('i').removeClass('fa-arrow-up');
      ele.find('i').addClass('fa-arrow-down');
      ele.parents().find('.hidden-content').hide(speed);
    }
  });
}

export { showMoreSeeLess };
