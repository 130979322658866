// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@fortawesome/fontawesome-free")
require("popper.js");
require("bootstrap");
require("jquery-validation");
require("jquery.cookie");
import $ from 'jquery';

window.$ = $;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { scrollTopHandler } from '../components/scroll_top';
import { showMoreSeeLess } from '../components/show-more-see-less';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener("turbolinks:load", function() {
  scrollTopHandler();
  showMoreSeeLess();

  $(document).on("click", ".dropdown-item", function(e){
    e.preventDefault()

    let img = $(this).find("img").clone()
    let text = $(this).find("span").clone()

    $("#dropdownMenuButton .align-items-cente").html(img)
    $("#dropdownMenuButton .align-items-cente").append(text)
    $("#contact_purpose").val(text.text())
  })

  $(document).on('click', 'a[href^="#"]:not(.not-a-scroll)', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
  });


  if ($.cookie('allow_cookies') == undefined) {
    if( $("#cookie-message").css('right').toLowerCase() === '-500px') {
      $("#cookie-message").animate({right: '0'}, 'slow');
    } else {
      $("#cookie-message").animate({bottom: '0'}, 'slow');
    }
  } else {
    $("#cookie-message").remove();
    $("#cookie-info-modal").remove();
  }

  $("#cookie-message .close").click(function(){
    $("#cookie-message").remove();
    $.cookie('allow_cookies', 'yes', { expires: 365 });
  });

  handleSubmitYoutubeId()
})

function handleSubmitYoutubeId(){
  const $form = $("#youtube_download")

  if ($form.length == 0) return

  $form.submit(function(e){
    e.preventDefault()

    $(".progress").addClass("d-none")
    $(".download-link").addClass("d-none")
    $("button[type='submit']").attr("disabled", true)
    $("button[type='submit'] span").text("Downloading...")

    let url = $form.attr("action")

    $.ajax({
      url: url,
      type: "POST",
      data: $form.serialize(),
      dataType: "json",
      success: function(data){
        if(data.status == "success"){
          $("body .alert").remove()
          $(".progress").removeClass("d-none")
          checkDownloadStatus(data.youtube_id)
        } else {
          $("body .alert").remove()
          $("body").append("<div class='alert alert-danger' role='alert'>Something went wrong. Please try again.</div>")
        }
      }
    })
  })
}

function checkDownloadStatus(youtube_id) {
  let url = `/youtube_downloads/${youtube_id}/check_status`

  $.ajax({
    url: url,
    type: "GET",
    dataType: "json",
    success: function(data){
      if(data.status == "ready"){
        $(".progress").addClass("d-none")
        $("button[type='submit']").attr("disabled", false)
        $("button[type='submit'] span").text("Download")
        $("body .alert").remove()
        
        $(".download-link").removeClass("d-none")
        $(".download-link a").attr("href", data.filename)
      } else {
        setTimeout(function(){
          checkDownloadStatus(youtube_id)
        }, 1000*3)
      }
    }
  })
}
